<template>
    <div id="app">
        <section class="section-container">
            <div class="edf-platform">
                <img src="/static/images/logo-edf.png">
                <h1>Choisissez la plateforme à laquelle vous souhaitez accéder</h1>
            </div>
            <div class="acces-platform">
                <div class="motor-select">
                    <div class="motor-select-div">
                        <img class="motor-select-div-logo" src="/static/images/logo-motor-select.png">
                        <div>
                            <a :href="motorSelectUrl" class="motor-select-div-color">Accéder à Motor Select <img src="/static/images/Arrow-right.svg"></a>
                        </div>
                        <p class="motor-select-div-audit" >Choisir vos moteurs à auditer</p>
                    </div>
                </div>
                <div class="motor-view">
                    <div class="motor-view-div">
                        <img class="motor-view-div-logo" src="/static/images/logo_motorview.png">
                        <div>
                            <a :href="motorViewUrl" class="motor-view-div-color">
                                Accéder à Motor View <img src="/static/images/Arrow-right.svg">
                            </a>
                        </div>
                        <p class="motor-view-div-audit">Accéder à des outils allégés</p>
                    </div>
                </div>
                <div class="edf-viveco">
                    <div class="edf-viveco-div">
                        <img  class="edf-viveco-div-logo" src="/static/images/logo-edf-viveco.png">
                        <div>
                            <a :href ="vivecoUrl"  class="edf-viveco-div-color">Accéder à EDF Viveco <img src="/static/images/Arrow-right.svg"></a>
                        </div>
                        <p class="edf-viveco-div-audit">Réaliser un audit moteur</p>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
    export default {
        name: 'App',
        components: {},
        data() {
            return {}
        },
        computed: {
            vivecoUrl(){
                return window.config.viveco_url
            },
            motorSelectUrl(){
                return window.config.motor_select_url
            },
            motorViewUrl(){
                return window.config.motor_view_url;
            }
        },
        methods: {}
    }
</script>

<style lang="scss">
    // @import "./assets/styles/app";
</style>
